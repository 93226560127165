
import type { PropType } from 'vue'
import { defineComponent } from 'vue'
import TopFilter from '@/components/layout/topFilter/TopFilter.vue'
import TmButton from '@/components/shared/TmButton.vue'

export default defineComponent({
  name: 'ContactsListsModalFilter',
  components: {
    TmButton,
    TopFilter,
  },
  props: {
    selected: {
      type: Array,
    },
    addType: {
      type: String as PropType<'list' | 'contact' | 'segment'>,
      default: 'list',
    },
  },
  emits: ['close'],
})
