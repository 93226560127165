
import { computed, defineComponent, ref } from 'vue'
import TmModal from '@/components/shared/modal/TmModal.vue'
import { useModes } from '@/compositions/modes'
import type { TableHeaders } from '@/definitions/shared/types'
import { getTableData } from '@/services/tableService'
import type { ContactList } from '@/definitions/contacts/lists/types'
import ContactsAllContactsTable from '@/components/pages/contacts/allContacts/ContactsAllContactsTable.vue'
import type { ContactLine } from '@/definitions/contacts/allContacts/types'
import ContactsListsModalFilter from '@/components/pages/contacts/lists/ContactsListsModalFilter.vue'

export default defineComponent({
  name: 'ContactsTableModal',
  components: {
    ContactsListsModalFilter,
    ContactsAllContactsTable,
    TmModal,
  },
  props: {
    title: {
      type: String,
      default: 'Contacts',
    },
    onSubmit: {
      type: Function,
      default: () => {},
    },
  },
  setup() {
    const { isEmptyMode } = useModes()
    const search = ref('')
    const selected = ref<ContactList[]>([])
    const tableHeaders = ref<TableHeaders[]>([
      { text: 'Name', value: 'full-name-slot' },
      { text: 'Phone', value: 'phone' },
      { text: 'Country', value: 'country', width: 180 },
    ])
    const tableItems = ref<ContactLine[]>(getTableData('contactsAllContacts'))
    const filteredTableItems = computed<ContactLine[]>(() => {
      return tableItems.value.filter((item) => item.person.fullName.toLowerCase().includes(search.value.toLowerCase()))
    })

    const addContacts = (func: (num: number) => void, func2: () => void) => {
      func(selected.value.length)
      func2()
    }

    return {
      isEmptyMode,
      search,
      selected,
      tableHeaders,
      tableItems,
      filteredTableItems,
      addContacts,
    }
  },
})
